@import "bootstrap/dist/css/bootstrap.min.css";

/*
@priamrry : #7aba1e
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Varela Round", sans-serif;
  font-weight: 200;
  color: rgba(0, 0, 0, 0.616);
}

.navbar-expand-sm .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.nav-link {
  display: block;
  padding: 0.5rem 0rem;
  padding-top: 0.5rem;
  padding-right: 0rem;
  padding-bottom: 0.5rem;
  padding-left: 0rem;
}

body {
  background: #000;
  min-height: 90vh;
  height: auto;
  background: snow;
  padding: 0rem 0.6rem 0rem 0.6rem;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  padding: 0rem 8rem;
  z-index: 10;
  width: 100%;
}
@media screen and (max-width: 576px) {
  .header {
    padding: 0rem 2rem;
  }
}

.banner {
  height: 100vh;
  background-image: url("./images/12.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  z-index: -1;
  margin-top: 0.62rem;
}

.banner1 {
  height: 100vh;
  background-image: url("./images/22.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  z-index: -1;
  margin-top: 0.62rem;
}

.banner2 {
  height: 100vh;
  background-image: url("./images/334.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  z-index: -1;
  margin-top: 0.62rem;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0rem 8rem;
  z-index: 100000;
  background-color: rgb(255, 255, 255);
  animation-duration: 1s;
  animation-name: menu_sticky;
  animation-timing-function: ease-out;
}

@media screen and (max-width: 576px) {
  .sticky {
    padding: 0rem 2rem;
  }
}

.navbar-nav .nav-link {
  padding-left: 3rem !important;
  transition: all ease;
}

.nav-link {
  color: #444;
  transition: all ease;
  font-size: 1.3vw;
  font-weight: 400;
}

@media screen and (max-width: 576px) {
  .nav-link {
    font-size: 2.8vw;
  }
}
.navbar-light a:hover {
  color: #74bd0f !important;
}

.banner {
  animation: zoomeffect 10s;
}

@keyframes zoomeffect {
  0% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

.banner1 {
  animation: zoomeffect 10s;
}

@keyframes zoomeffect {
  0% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

.banner2 {
  animation: zoomeffect 10s;
}

@keyframes zoomeffect {
  0% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes menu_sticky {
  0% {
    margin-top: -6.25rem;
  }

  100% {
    margin-top: 0px;
  }
}

.container-lg {
  width: 80%;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.main {
  display: flex;
  margin-top: 6rem;
  margin-bottom: 6rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 576px) {
  .main {
    height: 10rem;
  }
}
.side {
  background: url(../src/images/55.jpg);
  background-repeat: no-repeat;
  width: 45%;
  background-size: contain;
  height: 25rem;
  border-radius: 5px;
}
.h1side {
  width: 45%;
  font-size: 4vw;
}
.span1 {
  color: #74bd0f;
  font-weight: 600;
}
.h1side span {
  font-weight: 600;
  font-size: 3vw;
}

.home-p {
  font-size: 1.5rem;
  margin-top: 1rem;
}

.btn {
  font-size: 1.5vw;
}

.Footer {
  margin-top: 5%;
  padding: 1.25rem;
  background: #191e18;
  border-top: #74bd0f;
  border-radius: 5px;
  border-top-style: solid;
  border-width: 1.5vw;
}

.footer-t {
  color: #fff !important;
  font-size: 1.7vw;
  padding-bottom: 1vw;
}
.footerimage {
  background: url(../src/images/222.png);
  background-repeat: no-repeat;

  background-size: contain;
  height: 5rem;
}

@media screen and (max-width: 576px) {
  .footerimage {
    background-size: 100%;
  }
}

.row {
  text-align: center;
}

.carousel-control-next-icon {
  background-image: none !important;
}
.carousel-control-prev-icon {
  background-image: none !important;
}

.carousel-indicators {
  display: none;
}

.carousel-caption {
  position: absolute;
  top: 30%;
  left: 15%;
  z-index: 0;
  color: #fff !important;
}

.h3,
h3 {
  font-size: 3.5vw;
  font-weight: 700;
  color: #fff !important;
}

.carousel-caption p {
  font-weight: 200;
  font-size: 1.6vw;
  color: #fff !important;
}
.trade {
  background: #74bd0f;
  display: block;
  text-align: center;
  font-size: 1.3vw;
}

@media screen and (width: 320px) {
  .trade {
    background: #74bd0f;
    display: block;
    text-align: center;
    font-size: 2vw;
  }
  .carousel-caption p {
    font-weight: 200;
    font-size: 0.8rem;
    color: #fff !important;
  }
  .h3,
  h3 {
    font-size: 1.3rem;
    font-weight: 700;
    color: #fff !important;
  }
  .home-p {
    font-size: 0.6rem;
    margin-top: 1rem;
  }
  .h1side {
    width: 50%;
    font-size: 1vw;
  }
  .about {
    font-size: 1rem !important;
    color: #000 !important;
    font-weight: 200;
    margin-bottom: 5vh;
    margin-top: 5vh;
  }
  .about_head {
    text-align: center;
    font-size: 1.2rem !important;
    color: #000 !important;
    font-weight: bold;
  }
  .nour {
    color: #000 !important;
    font-size: 0.6rem !important;
  }
  .nour-des {
    font-size: 0.5rem !important;
    text-align: center;
  }
  .contact-img {
    height: 45vh !important;
  }
  .card-title {
    margin-bottom: 0.75rem;
    font-size: 3vw !important;
    margin-top: 0.5rem;
    font-weight: bold;
  }

  .contactmargin {
    font-size: 2.5vw !important;
  }
  .contactmarginn {
    font-size: 2.5vw !important;
  }
  .ourservice-img {
    height: 60vw !important;
  }
  .ourservice-h1 {
    font-size: 2vw !important;
    margin-top: 3vw;
    margin-bottom: 2.5vw;
    color: rgb(0, 0, 0) !important;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    margin-left: 30%;
  }
  .footer-t {
    color: #fff !important;
    font-size: 2.8vw;
    padding-bottom: 1vw;
    padding-top: 2vw;
    margin-bottom: none !important;
  }
  .footer-left {
    margin-left: 0rem !important;
    display: inline-table;
    padding-top: 0.4rem;
  }
  .footerimage {
    background: url(../src/images/222.png);
    background-repeat: no-repeat;

    background-size: contain;
    height: 2rem;
  }
}

@media screen and (max-width: 375px) {
  .trade {
    background: #74bd0f;
    display: block;
    text-align: center;
    font-size: 2vw;
  }
  .carousel-caption p {
    font-weight: 200;
    font-size: 0.8rem;
    color: #fff !important;
  }
  .h3,
  h3 {
    font-size: 1.3rem;
    font-weight: 700;
    color: #fff !important;
  }
  .home-p {
    font-size: 0.6rem;
    margin-top: 1rem;
  }
  .h1side {
    width: 50%;
    font-size: 1vw;
  }
  .about {
    font-size: 1rem !important;
    color: #000 !important;
    font-weight: 200;
    margin-bottom: 5vh;
    margin-top: 5vh;
  }
  .about_head {
    text-align: center;
    font-size: 1.2rem !important;
    color: #000 !important;
    font-weight: bold;
  }
  .nour {
    color: #000 !important;
    font-size: 0.6rem !important;
  }
  .nour-des {
    font-size: 0.5rem !important;
    text-align: center;
  }
  .contact-img {
    height: 45vh !important;
  }
  .card-title {
    margin-bottom: 0.75rem;
    font-size: 2vw !important;
    margin-top: 0.5rem;
    font-weight: bold;
  }

  .contactmargin {
    font-size: 2.5vw !important;
  }
  .contactmarginn {
    font-size: 2.5vw !important;
  }
  .ourservice-img {
    height: 60vw !important;
  }
  .ourservice-h1 {
    font-size: 2vw !important;
    margin-top: 3vw;
    margin-bottom: 2.5vw;
    color: rgb(0, 0, 0) !important;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    margin-left: 30%;
  }
  .footer-t {
    color: #fff !important;
    font-size: 2.8vw;
    padding-bottom: 1vw;
  }
  .footer-left {
    margin-left: 0rem !important;
    display: inline-table;
    padding-top: 0.4rem;
  }
}

@media screen and (max-width: 425px) {
  .trade {
    background: #74bd0f;
    display: block;
    text-align: center;
    font-size: 2vw;
  }
  .carousel-caption p {
    font-weight: 200;
    font-size: 0.8rem;
    color: #fff !important;
  }
  .h3,
  h3 {
    font-size: 1.3rem;
    font-weight: 700;
    color: #fff !important;
  }
  .home-p {
    font-size: 0.6rem;
    margin-top: 1rem;
  }
  .h1side {
    width: 50%;
    font-size: 1vw;
  }
  .about {
    font-size: 1rem !important;
    color: #000 !important;
    font-weight: 200;
    margin-bottom: 5vh;
    margin-top: 5vh;
  }
  .about_head {
    text-align: center;
    font-size: 1.2rem !important;
    color: #000 !important;
    font-weight: bold;
  }
  .nour {
    color: #000 !important;
    font-size: 0.6rem !important;
  }
  .nour-des {
    font-size: 0.5rem !important;
    text-align: center;
  }
  .contact-img {
    height: 45vh !important;
  }
  .card-title {
    margin-bottom: 0.75rem;
    font-size: 2vw !important;
    margin-top: 0.5rem;
    font-weight: bold;
  }

  .contactmargin {
    font-size: 2vw !important;
  }
  .contactmarginn {
    font-size: 2vw !important;
  }
  .ourservice-img {
    height: 60vw !important;
  }
  .ourservice-h1 {
    font-size: 2vw !important;
    margin-top: 3vw;
    margin-bottom: 2.5vw;
    color: rgb(0, 0, 0) !important;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    margin-left: 30%;
  }
  .footer-t {
    color: #fff !important;
    font-size: 2.8vw;
    padding-bottom: 1vw;
  }
  .footer-left {
    margin-left: 0rem !important;
    display: inline-table;
    padding-top: 0.4rem;
  }
}

@media screen and (max-width: 575px) {
  .trade {
    background: #74bd0f;
    display: block;
    text-align: center;
    font-size: 1vw;
  }
  .carousel-caption p {
    font-weight: 200;
    font-size: 0.8rem;
    color: #fff !important;
  }
  .h3,
  h3 {
    font-size: 1.3rem;
    font-weight: 700;
    color: #fff !important;
  }
  .home-p {
    font-size: 0.6rem;
    margin-top: 1rem;
  }
  .h1side {
    width: 50%;
    font-size: 1vw;
  }
  .about {
    font-size: 1rem !important;
    color: #000 !important;
    font-weight: 200;
    margin-bottom: 5vh;
    margin-top: 5vh;
  }
  .about_head {
    text-align: center;
    font-size: 1.2rem !important;
    color: #000 !important;
    font-weight: bold;
  }
  .nour {
    color: #000 !important;
    font-size: 0.6rem !important;
  }
  .nour-des {
    font-size: 0.5rem !important;
    text-align: center;
  }
  .contact-img {
    height: 45vh !important;
  }
  .card-title {
    margin-bottom: 0.75rem;
    font-size: 2vw !important;
    margin-top: 0.5rem;
    font-weight: bold;
  }

  .contactmargin {
    font-size: 2vw !important;
  }
  .contactmarginn {
    font-size: 2vw !important;
  }
  .ourservice-img {
    height: 60vw !important;
  }
  .ourservice-h1 {
    font-size: 2vw !important;
    margin-top: 3vw;
    margin-bottom: 2.5vw;
    color: rgb(0, 0, 0) !important;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    margin-left: 30%;
  }
  .footer-t {
    color: #fff !important;
    font-size: 2.8vw;
    padding-bottom: 1vw;
  }
  .footer-left {
    margin-left: 0rem !important;
    display: inline-table;
    padding-top: 0.4rem;
  }
}
@media screen and (max-width: 575px) {
  .trade {
    background: #74bd0f;
    display: block;
    text-align: center;
    font-size: 1vw;
  }
  .carousel-caption p {
    font-weight: 200;
    font-size: 0.9rem;
    color: #fff !important;
  }
  .h3,
  h3 {
    font-size: 1.3rem;
    font-weight: 700;
    color: #fff !important;
  }
  .home-p {
    font-size: 0.8rem;
    margin-top: 1rem;
  }
  .h1side {
    width: 50%;
    font-size: 1vw;
  }
  .about {
    font-size: 1rem !important;
    color: #000 !important;
    font-weight: 200;
    margin-bottom: 5vh;
    margin-top: 5vh;
  }
  .about_head {
    text-align: center;
    font-size: 1.2rem !important;
    color: #000 !important;
    font-weight: bold;
  }
  .nour {
    color: #000 !important;
    font-size: 0.6rem !important;
  }
  .nour-des {
    font-size: 0.5rem !important;
    text-align: center;
  }
  .contact-img {
    height: 45vh !important;
  }
  .card-title {
    margin-bottom: 0.75rem;
    font-size: 2vw !important;
    margin-top: 0.5rem;
    font-weight: bold;
  }

  .contactmargin {
    font-size: 2vw !important;
  }
  .contactmarginn {
    font-size: 2vw !important;
  }
  .ourservice-img {
    height: 60vw !important;
  }
  .ourservice-h1 {
    font-size: 2vw !important;
    margin-top: 3vw;
    margin-bottom: 2.5vw;
    color: rgb(0, 0, 0) !important;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    margin-left: 30%;
  }
  .footer-t {
    color: #fff !important;
    font-size: 2.8vw;
    padding-bottom: 1vw;
  }
  .footer-left {
    margin-left: 0rem !important;
    display: inline-table;
    padding-top: 0.4rem;
  }
}
.ourservice-img {
  height: 40vw;
}
.contactimgs {
  height: 10vh;
}

.navbar-brand {
  display: inline-block;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.card-deck {
  margin-bottom: 50px;
}

.about {
  font-size: 2vw;
  color: #000 !important;
  font-weight: 200;
  margin-bottom: 5vh;
  margin-top: 5vh;
  text-align: center;
}

.about_head {
  text-align: center;
  font-size: 2.5vw;
  color: #000 !important;
  font-weight: bold;
}

.about-h1 {
  margin-top: 5%;
  font-size: 3vw !important;
  margin-top: 1rem;
  color: rgb(0, 0, 0);
  text-align: center;
}

.contact-h1 {
  margin-top: 9% !important;
  font-size: 3vw !important;
  margin-top: 1rem;
  color: rgb(255, 255, 255);
  font-family: cursive;
}

.contact-photo {
  margin-top: 5%;
  margin-bottom: 3rem;
  font-size: 1.3vw !important;
}
.dashed {
  border-color: #74bd0f;
  border-top-width: 5px;
  width: 80%;
  margin-bottom: 4vh;
}
.about-center {
  width: 10%;

  margin-left: 46%;
}

.card-title {
  text-align: center;
}

.nour {
  color: #000 !important;
  font-size: 1.6vw;
}

.nour-des {
  font-size: 1.6vw;
  text-align: center;
  margin-top: 10px;
}

.nour_img {
  width: 16vw !important;
  height: 16vw !important;
  margin-bottom: 1vh;
}
.ourservice-h1 {
  font-size: 1.7vw;
  margin-top: 3vw;
  margin-bottom: 2.5vw;
  color: rgb(0, 0, 0) !important;
}

.col {
  font-size: 1.6vw;
}

li {
  margin-bottom: 1vw;
  list-style: none;
  text-align: center;
}

ul {
  padding-top: 3vw;
}
.contactmargin {
  margin-top: 1.6vw;
  font-size: 1.5vw;
}
.contactmarginn {
  margin-top: 0.5vw;
  font-size: 1.5vw;
}

.col-service {
  font-size: 0.7rem;
  margin-right: 2.5vw;
  margin-left: 1.5vw;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 2vw;
}

.row-footer {
  align-items: center;
}

.img-height {
  height: 17vw;
  width: 24vw;
}

hr {
  margin-top: 0.2rem !important;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.social {
  height: 3vw;
  width: 3vw;
}
.card-title {
  margin-bottom: 0.75rem;

  margin-top: 0.5rem;
}

.card-body {
  padding-bottom: 0.2rem !important;
  padding-top: 0.2rem !important;
  padding-right: 0.2rem !important;
  padding-left: 0.2rem !important;
}

.card-img,
.card-img-bottom,
.card-img-top {
  flex-shrink: 0;
  width: 100%;
  margin-top: 0.2rem;
}

.footer-left {
  margin-left: 0.5rem;
  padding-top: 1vw;
}

.card-deck {
  display: flex;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 0px;
}

@media screen and (min-width: 375px) {
  .card-deck {
    display: flex;
    flex-flow: nowrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}

.row {
  display: flex;
  flex-wrap: nowrap;
  margin-right: -15px;
  margin-left: -15px;
  align-content: center;
}

.contact-img {
  height: 20rem;
}
